<template>
    <div class="m-2">
        <bread-crumbs :items="items" />
        <v-tabs
        grow
        v-model="active_tab"
        >
            <v-tab
            v-for="tab of tabs"
            :key="tab.index"
            :to="tab.href"
            >
            {{tab.name}}
            </v-tab>
        </v-tabs>
        

    </div>
</template>

<script>
import BreadCrumbs from '@/components/breadCrumbs.vue'
export default{
    components:{},
    data() {
   
        return {
            active_tab:0,
            items:[
                // {
                //     text: this.$store.state.hometitle,
                //     disabled: false,
                //     to: '/home',
                // },
                // {
                //     text: 'التقارير',
                //     disabled: true,
                //     to: '/reports',
                // },
                {
                    text: 'تقرير البطاقات',
                    disabled: true,
                    to: '/reports/cards-reports',
                },
            ],
            tabs: [
                {
                    index:3,name:'تقرير الضريبة',href:'/reports/vat-reports',class:'mytab4'
                },
                {
                    index:0,name:'تقرير اليومية',href:'/reports/daily-reports',class:'mytab1'
                },
                {
                    index:1,name:'تقرير البطاقات',href:'/reports/cards-reports',class:'mytab2'
                },
                {
                    index:2,name:'تقرير المبيعات',href:'/reports/sales-reports',class:'mytab3'
                },
                {
                    index:4,name:'تقرير النسب',href:'/reports/percent-reports',class:'mytab5'
                }
            ]
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
    },
}
</script>